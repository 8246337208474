import React, {MouseEvent, useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../style/CommonStyle'
import {CBusDispatchTable} from '../../../../../model/Bus'
import {isEmptyString, isNil} from '../../../../../util/ValidationUtil'
import CheckBox from '../../../../common/CheckBox'

type Props = {
  isStart: boolean
  dispatchTable: CBusDispatchTable
  lengthPerCell: number
  selectedDispatchTables: CBusDispatchTable[]
  isDeleting: boolean
  isUpdatingStudents: boolean
  onClickInfo(e: MouseEvent<HTMLElement>): void
  onClickCell(dt: CBusDispatchTable): void
}

export default function PeriodTableItemExist(props: Props) {
  // console.log(props.dispatchTable)
  const timeText = useMemo(() => {
    if (isEmptyString(props.dispatchTable.dispatch.courseTime)) {
      return '--:--'
    }

    return `${props.dispatchTable.dispatch.courseTime}`
  }, [props.dispatchTable])

  const managerText = useMemo(() => {
    if (
      isNil(props.dispatchTable.manager) ||
      props.dispatchTable.manager.isEmpty()
    ) {
      return '매니저 미지정'
    }

    return `${
      props.dispatchTable.manager.name
    }(${props.dispatchTable.manager.phone.slice(9)})`
  }, [props.dispatchTable])

  const isSufficient = useMemo(() => {
    return !props.dispatchTable.manager.isEmpty()
  }, [props.dispatchTable])

  const onClickCell = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      if (props.isDeleting) {
        if (!props.dispatchTable.status.isCanDelete) {
          return
        }

        props.onClickCell(props.dispatchTable)
        return
      }

      if (props.isUpdatingStudents) {
        props.onClickCell(props.dispatchTable)
        return
      }

      props.onClickInfo(e)
    },
    [
      props.onClickCell,
      props.onClickInfo,
      props.isDeleting,
      props.isUpdatingStudents,
      props.dispatchTable,
    ],
  )

  const isSelected = useMemo(() => {
    return props.selectedDispatchTables.some(
      sdt =>
        sdt.dispatch.dispatchCode === props.dispatchTable.dispatch.dispatchCode,
    )
  }, [props.dispatchTable, props.selectedDispatchTables])

  const CheckComponent = useMemo(() => {
    if (!props.dispatchTable.status.isCanDelete) {
      return null
    }
    if (!props.isDeleting && !props.isUpdatingStudents) {
      return null
    }
    if (
      props.dispatchTable.dispatch.dispatchType.value === 'CIRCULATION' &&
      !props.isDeleting
    ) {
      return null
    }
    return (
      <CheckBox onClick={onClickCell} readOnly={false} isChecked={isSelected} />
    )
  }, [
    isSelected,
    props.dispatchTable,
    props.isDeleting,
    props.isUpdatingStudents,
  ])

  const isMultiDispatch = useMemo(() => {
    return props.lengthPerCell > 1
  }, [props.lengthPerCell])

  const DateComponent = useMemo(() => {
    const sd = props.dispatchTable.dispatch.startDate
    const ed = props.dispatchTable.dispatch.endDate

    if (!isMultiDispatch) {
      return null
    }

    if (sd.equals(ed)) {
      return (
        <DateContainer>
          {CheckComponent}
          <Date>{sd.toString()}</Date>
        </DateContainer>
      )
    }

    // if (sd.toYear() === ed.toYear() && sd.toMonth() === ed.toMonth()) {
    //   return (
    //     <DateContainer>
    //       {CheckComponent}
    //       <Date>
    //         {sd.toString()} ~ {ed.toDate()}
    //       </Date>
    //     </DateContainer>
    //   )
    // }

    return (
      <DateContainer>
        {CheckComponent}
        <Date>
          {sd.toString()} ~ {ed.toString()}
        </Date>
      </DateContainer>
    )
  }, [props.dispatchTable, CheckComponent, isMultiDispatch])

  const HeaderComponent = useMemo(() => {
    return (
      <Header color={props.isStart ? '#009a17' : '#4B84DD'}>
        {props.dispatchTable.dispatch.dispatchType.exposure}
      </Header>
    )
  }, [props.dispatchTable, props.isStart])

  const HeaderContainerComponent = useMemo(() => {
    if (isMultiDispatch) {
      return <HeaderContainer>{HeaderComponent}</HeaderContainer>
    }

    return (
      <HeaderContainer>
        {CheckComponent}
        {HeaderComponent}
      </HeaderContainer>
    )
  }, [isMultiDispatch, HeaderComponent, CheckComponent])

  return (
    <Container
      isStart={props.isStart}
      isSufficient={isSufficient}
      onClick={onClickCell}>
      {DateComponent}
      <HeaderAndInfoContainer>
        {HeaderContainerComponent}
        <InfoIconContainer>
          {props.dispatchTable.status.isFullSeat ? (
            <FullSeatText>만차</FullSeatText>
          ) : null}

          {!props.dispatchTable.status.isRegisterAllSchedule ? (
            <NotRegisteredText>학생 미등록</NotRegisteredText>
          ) : null}
        </InfoIconContainer>
      </HeaderAndInfoContainer>
      <TimeText>{timeText}</TimeText>
      <CourseNameText>
        {isEmptyString(props.dispatchTable.dispatch.courseName)
          ? '-'
          : props.dispatchTable.dispatch.courseName}
      </CourseNameText>
      <ManagerText>{managerText}</ManagerText>
    </Container>
  )
}

const Container = styled.div<{isStart: boolean; isSufficient: boolean}>`
  flex: 1;
  ${flexColumn};
  row-gap: 0.4rem;
  padding: 0.8rem;
  /* min-height: 8.8rem; */
  height: 100%;
  background: ${props =>
    !props.isSufficient ? '#F5F5F5' : props.isStart ? '#EEF6F0' : '#eaf0fb'};
  cursor: pointer;
`

const Date = styled.div`
  color: #55024d;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const HeaderAndInfoContainer = styled.div`
  ${flexRow};
  justify-content: space-between;
  align-items: center;
`

const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
`

const Header = styled.div<{color: string}>`
  color: ${props => props.color};
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`

const TimeText = styled.div`
  color: #1a1a1a;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`

const CourseNameText = styled.div`
  color: #1a1a1a;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`

const ManagerText = styled.div`
  color: #666666;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const InfoIconContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.2rem;
`

const FullSeatText = styled.div`
  ${flexRow};
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 1.2rem;
  background: #da291c;
  color: #ffffff;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  border-radius: 0.8rem;
`

const NotRegisteredText = styled.div`
  ${flexRow};
  justify-content: center;
  align-items: center;
  width: 4.4rem;
  height: 1.2rem;
  background: #ffffff;
  color: #ff9133;
  text-align: center;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  border-radius: 0.8rem;
`

const DateContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
`
