import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'

import {flexColumn} from '../../../../../style/CommonStyle'
import {CDispatchPeriodInfo} from '../../../../../model/DispatchPeriod'
import {CBusBrief} from '../../../../../model/Bus'
import {SelectedBusInfo} from '../table/PeriodTable'
import {CCourse} from '../../../../../model/Course'
import {Optional} from '../../../../../type/Common'
import BasicInfo from './basic/BasicInfo'
import OperationInfo from './operation/OperationInfo'
import DispatchAddRouteContainer from './route/DispatchAddRouteContainer'
import {
  CDispatchCirculationTime,
  CDispatchWeekdayWithCode,
} from '../../../../../model/Dispatch'
import {
  getDispatchSameCourseWeekday,
  GetDispatchSameCourseWeekdayData,
} from '../../../../../service/dispatch/Dispatch'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../recoil/Atom'
import {isNil} from '../../../../../util/ValidationUtil'
import {CirculationDispatches} from './DispatchAdd'

type Props = {
  busBrief: CBusBrief
  dispatchPeriod: CDispatchPeriodInfo
  selectedBusInfo: SelectedBusInfo
  selectedCourse: Optional<CCourse>
  circulationTime: Optional<CDispatchCirculationTime>
  onChangeCourse(course: CCourse): void
  onChangeWeekday(wdcs: CDispatchWeekdayWithCode[]): void
  onChangeManager(dispatchCode: string, managerID: number): void
  onChangeCirculationTime(ct: CDispatchCirculationTime): void
  circulationDispatches: CirculationDispatches[]
}

export default function DispatchAddManage(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)

  const [sameCourseWeekdays, setSameCourseWeekdays] =
    useState<Optional<CDispatchWeekdayWithCode[]>>(null)
  const [selectedWeekdays, setSelectedWeekdays] = useState<
    CDispatchWeekdayWithCode[]
  >([])

  const fetchSameCourseWeekday = useCallback(
    (data: GetDispatchSameCourseWeekdayData) => {
      getDispatchSameCourseWeekday(data)
        .then(scw => {
          setSameCourseWeekdays(scw)
        })
        .catch(
          error =>
            `getDispatchSameCourseWeekday() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
        )
    },
    [],
  )

  const onChangeWeekdays = useCallback(
    (wdwc: CDispatchWeekdayWithCode[]) => {
      setSelectedWeekdays(wdwc)
      props.onChangeWeekday(wdwc)
    },
    [setSelectedWeekdays, props.onChangeWeekday],
  )

  const operationTime = useMemo(() => {
    if (props.selectedBusInfo.isStart) {
      return `${props.selectedBusInfo.classTime} | 등원`
    }

    return `${props.selectedBusInfo.classTime} | 하원`
  }, [props.selectedBusInfo])

  useEffect(() => {
    const data: GetDispatchSameCourseWeekdayData = {
      dispatchCode: props.selectedBusInfo.dispatchTable.dispatch.dispatchCode,
      academyID: academyID,
    }

    fetchSameCourseWeekday(data)
  }, [props.selectedBusInfo, academyID])

  if (isNil(sameCourseWeekdays)) {
    return null
  }

  return (
    <Container>
      <BasicInfo
        busBrief={props.busBrief}
        dispatchPeriod={props.dispatchPeriod}
        selectedBusInfo={props.selectedBusInfo}
        isStart={props.selectedBusInfo.isStart}
      />
      <OperationInfo
        dispatchPeriod={props.dispatchPeriod}
        selectedCourse={props.selectedCourse}
        selectedBusInfo={props.selectedBusInfo}
        sameCourseWeekdays={sameCourseWeekdays}
        originalTime={operationTime}
        onChangeWeekdays={onChangeWeekdays}
        onChangeCourse={props.onChangeCourse}
        onChangeCirculationTime={props.onChangeCirculationTime}
      />
      <DispatchAddRouteContainer
        selectedWeekdaysWitchCode={selectedWeekdays}
        selectedCourse={props.selectedCourse}
        selectedBusInfo={props.selectedBusInfo}
        startDate={props.dispatchPeriod.startDate}
        circulationTime={props.circulationTime}
        endDate={props.dispatchPeriod.endDate}
        onChangeManager={props.onChangeManager}
        circulationDispatches={props.circulationDispatches}
        onChangeWeekday={props.onChangeWeekday}
      />
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  ${flexColumn};
  padding: 1.6rem;
  background: #f5f5f5;
  row-gap: 1.6rem;
  overflow: auto;
`
