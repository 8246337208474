import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../style/CommonStyle'
import DispatchAddRouteItem, {
  ArrowDownImage,
  ManagerInput,
  ManagerInputContainer,
  OperationRouteInputText,
  OperationRouteInputTextContainer,
} from './DispatchAddRouteItem'
import {CCourse} from '../../../../../../model/Course'
import {isNil} from '../../../../../../util/ValidationUtil'
import {CDate} from '../../../../../../model/Date'
import {
  CDispatchCirculationTime,
  CDispatchCourseWithStudent,
  CDispatchWeekdayWithCode,
} from '../../../../../../model/Dispatch'
import {SelectedBusInfo} from '../../table/PeriodTable'
import {DispatchTypeEnum} from '../../../../../../enum/DispatchTypeEnum'
import {Optional} from '../../../../../../type/Common'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../../recoil/Atom'
import {StudentLoadTypeEnum} from '../../../../../../enum/StudentLoadTypeEnum'
import {
  getDispatchesCoursesWithStudent,
  GetDispatchesCoursesWithStudentsData,
  postDispatchesCoursesStudents,
  PostDispatchesCoursesStudentsData,
} from '../../../../../../service/dispatch/Dispatch'
import {alertError, throwError} from '../../../../../../util/ErrorUtil'
import ManagerSelect, {ManagerSelectRef} from './manager/ManagerSelect'
import useSecureRef from '../../../../../../hook/useSecureRef'
import {CManager, CManagerVector} from '../../../../../../model/Manager'
import ArrowDown from '../../../../../../asset/image/arrowDown.svg'
import LoadIcon from '../../../../../../asset/image/student.svg'
import SVGImage from '../../../../../common/SVGImage'
import {CirculationDispatches} from '../DispatchAdd'

type Props = {
  selectedWeekdaysWitchCode: CDispatchWeekdayWithCode[]
  selectedCourse: CCourse
  selectedBusInfo: SelectedBusInfo
  startDate: CDate
  endDate: CDate
  circulationTime: Optional<CDispatchCirculationTime>
  onChangeManager(dc: string, managerID: number): void
  circulationDispatches: CirculationDispatches[]
  onChangeWeekday(wdcs: CDispatchWeekdayWithCode[]): void
}

export default function DispatchAddRouteContainer(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)

  const managerSelectRef = useSecureRef<ManagerSelectRef>(
    '[DispatchAddRouteItem.tsx] managerSelectRef',
  )
  const [selectedManager, setSelectedManager] =
    useState<Optional<CManager>>(null)
  const [isAllManagerSame, setIsAllManagerSame] = useState<boolean>(false)
  const onChangeManager = useCallback(
    (sm: CManager) => {
      setSelectedManager(sm)
      props.selectedWeekdaysWitchCode.forEach(dispatch => {
        props.onChangeManager(dispatch.dispatchCode, sm.id)
      })
      setIsAllManagerSame(true)
    },
    [
      setIsAllManagerSame,
      setSelectedManager,
      props.onChangeManager,
      props.selectedWeekdaysWitchCode,
    ],
  )
  const onClickSelectManager = useCallback(() => {
    managerSelectRef.current().show()
  }, [])
  const ManagerComponent = useMemo((): ReactNode => {
    if (isNil(selectedManager)) {
      return (
        <OperationRouteInputText hasValue={!isNil(selectedManager)}>
          매니저 선택
        </OperationRouteInputText>
      )
    }

    if (isAllManagerSame === false) {
      return (
        <OperationRouteInputText hasValue={null}>
          매니저 선택
        </OperationRouteInputText>
      )
    }

    const managerText = `${selectedManager.name}(${selectedManager.phone.slice(
      9,
    )})`

    return (
      <OperationRouteInputTextContainer>
        <OperationRouteInputText hasValue={!isNil(selectedManager)}>
          {managerText.length > 9
            ? `${managerText.slice(0, 9)}...`
            : managerText}
        </OperationRouteInputText>
      </OperationRouteInputTextContainer>
    )
  }, [selectedManager, isAllManagerSame, setIsAllManagerSame])

  const [postedData, setPostedData] = useState<
    PostDispatchesCoursesStudentsData[]
  >([])

  const [postedDataExtra, setPostedDataExtra] = useState<
    PostDispatchesCoursesStudentsData[]
  >([])

  const fetchCourses = useCallback(
    async (data: GetDispatchesCoursesWithStudentsData, isExtra: boolean) => {
      const cws = await getDispatchesCoursesWithStudent(data)
        .then(async cws => {
          // if (isExtra) {
          //   setExtraCourses(cws)
          //   return
          // }

          // setCourses(cws)
          return cws
        })
        .catch(error => {
          throwError(
            error,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
          alertError(error, `Failed to fetch courses. Please try again later.`)
          // return []
        })
      return cws
    },
    [],
  )

  const postDispatchCoursesStudents = useCallback(
    async (data: PostDispatchesCoursesStudentsData) => {
      postDispatchesCoursesStudents(data)
        .then(response => {
          setPostedData(prevData => [...prevData, data])
        })
        .catch(error => {
          alertError(
            error,
            `postDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
          alertError(error, `Failed to fetch courses. Please try again later.`)
          // return []
        })
    },
    [],
  )

  const postDispatchCoursesStudentsExtra = useCallback(
    async (data: PostDispatchesCoursesStudentsData) => {
      postDispatchesCoursesStudents(data)
        .then(response => {
          setPostedDataExtra(prevData => [...prevData, data])
        })
        .catch(error => {
          alertError(
            error,
            `postDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
          alertError(error, `Failed to fetch courses. Please try again later.`)
          // return []
        })
    },
    [],
  )

  const loadAllStudents = useCallback(async () => {
    if (isNil(props.selectedCourse)) {
      console.error('Selected course is null or undefined')
      return
    }

    const selectedDispatches = props.selectedWeekdaysWitchCode

    await Promise.all(
      selectedDispatches.map(async selectedDispatch => {
        const fetchData: GetDispatchesCoursesWithStudentsData = {
          academyID: academyID,
          dispatchCode: selectedDispatch.dispatchCode,
          courseCode: props.selectedCourse.code,
          type: StudentLoadTypeEnum.CREATE,
        }

        const dispatchCourseWithStudent = await fetchCourses(fetchData, false)

        const postData: PostDispatchesCoursesStudentsData = {
          academyID: academyID,
          dispatchCode: selectedDispatch.dispatchCode,
          courseCode: props.selectedCourse.code,
          courses: dispatchCourseWithStudent || [],
          type: StudentLoadTypeEnum.CREATE,
        }

        await postDispatchCoursesStudents(postData)

        if (isNil(props.circulationTime)) {
          return
        }

        console.log(props.circulationDispatches)
        await Promise.all(
          props.circulationDispatches.map(async circulationDispatch => {
            const fetchDataExtra: GetDispatchesCoursesWithStudentsData = {
              academyID: academyID,
              dispatchCode: circulationDispatch.circulationDispatchCode,
              courseCode: props.selectedCourse.code,
              type: StudentLoadTypeEnum.CREATE,
            }

            const dispatchCourseWithStudentExtra = await fetchCourses(
              fetchDataExtra,
              true,
            )

            const postDataExtra: PostDispatchesCoursesStudentsData = {
              academyID: academyID,
              dispatchCode: circulationDispatch.circulationDispatchCode,
              courseCode: props.selectedCourse.code,
              courses: dispatchCourseWithStudentExtra || [],
              type: StudentLoadTypeEnum.CREATE,
            }

            await postDispatchCoursesStudentsExtra(postDataExtra)
          }),
        )
      }),
    )
  }, [
    props.selectedWeekdaysWitchCode,
    props.selectedCourse,
    props.circulationTime,
    props.circulationDispatches, // 추가
    fetchCourses,
    postDispatchCoursesStudents,
    postDispatchCoursesStudentsExtra,
  ])

  const mangerSelectStyle = useMemo(() => {
    return {left: -171}
  }, [])

  const ManagerInputContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ManagerInputContainerRef.current &&
        !ManagerInputContainerRef.current.contains(event.target as Node)
      ) {
        managerSelectRef.current().hide()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [managerSelectRef])

  const LoadStudentComponent = useMemo(() => {
    if (isNil(props.selectedCourse)) {
      return null
    }

    if (
      props.selectedCourse.dispatchType === DispatchTypeEnum.CIRCULATION &&
      isNil(props.circulationTime)
    ) {
      return null
    }

    return (
      <WeekdayContainer>
        <UtilContainer>
          <ManagerInputContainer ref={ManagerInputContainerRef}>
            <ManagerSelect
              ref={managerSelectRef.ref}
              selectedManager={selectedManager}
              onChange={onChangeManager}
              style={mangerSelectStyle}
              width={300}
            />
            <ManagerInput
              hasValue={!isNil(selectedManager)}
              onClick={onClickSelectManager}>
              {ManagerComponent}
              <ArrowDownImage source={ArrowDown} />
            </ManagerInput>
          </ManagerInputContainer>
          <LoadAllStudentsButton onClick={loadAllStudents}>
            <LoadAllButtonImage source={LoadIcon} />
            학생 일괄 불러 오기
          </LoadAllStudentsButton>
        </UtilContainer>
        {props.selectedWeekdaysWitchCode.map((sw, index) => {
          const loadedCourses: CDispatchCourseWithStudent[] =
            postedData.find(
              postedData => postedData.dispatchCode === sw.dispatchCode,
            )?.courses || []
          const loadedCoursesExtra: CDispatchCourseWithStudent[] =
            postedDataExtra.find(
              postedDataExtra =>
                postedDataExtra.dispatchCode ===
                props.circulationTime.dispatchCode,
            )?.courses || []
          return (
            <DispatchAddRouteItem
              key={sw.weekday.value}
              index={index}
              weekdayWithCode={sw}
              selectedCourse={props.selectedCourse}
              dispatchCode={sw.dispatchCode}
              startDate={props.startDate}
              endDate={props.endDate}
              circulationTime={props.circulationTime}
              selectedBusInfo={props.selectedBusInfo}
              onChangeManager={props.onChangeManager}
              loadedCourses={loadedCourses}
              loadedCoursesExtra={loadedCoursesExtra}
              selectedManagerForAll={selectedManager}
              setIsAllManagerSame={setIsAllManagerSame}
            />
          )
        })}
      </WeekdayContainer>
    )
  }, [
    isAllManagerSame,
    selectedManager,
    postedData,
    postedDataExtra,
    props.selectedCourse,
    props.circulationTime,
    props.selectedWeekdaysWitchCode,
    props.startDate,
    props.endDate,
    props.selectedBusInfo,
    props.circulationDispatches,
  ])

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>노선 시간 확정 및 학생, 매니저 등록</HeaderText>
      </HeaderContainer>
      {LoadStudentComponent}
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  position: relative;
`
const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
  margin-bottom: 10px;
`

const HeaderText = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const WeekdayContainer = styled.div`
  ${flexRow};
  column-gap: 1rem;
  row-gap: 0.8rem;
  flex-wrap: wrap;
`

const UtilContainer = styled.div`
  ${flexRow};
  align-items: center;
  position: absolute;
  top: -4px;
  right: 0;
  gap: 6px;
`

const LoadAllStudentsButton = styled.div`
  ${flexRow};
  height: 30px;
  background: #ffd100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3.4rem;
  cursor: pointer;
  padding: 0 10px;
  font-size: 11px;
  &:hover {
    font-weight: bold;
  }
`

const ManagerSelectTitle = styled.div`
  font-size: 10px;
  display: inlin-block;
  margin-right: 5px;
`

const LoadAllButtonImage = styled(SVGImage)`
  width: 18px;
  height: 18px;
`
