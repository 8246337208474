import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../style/CommonStyle'
import Footer from '../../../../common/Footer'
import DispatchAddManage from './DispatchAddManage'
import ConfirmModal, {ConfirmModalRef} from '../../../../modal/ConfirmModal'
import useSecureRef from '../../../../../hook/useSecureRef'
import {CDispatchPeriodInfo} from '../../../../../model/DispatchPeriod'
import {CBusBrief} from '../../../../../model/Bus'
import {SelectedBusInfo} from '../table/PeriodTable'
import {
  deleteDispatchesCoursesWithStudents,
  DeleteDispatchesCoursesWithStudentsData,
  DispatchSchedule,
  getDispatchCirculationTime,
  postDispatches,
  postDispatchesCirculation,
  PostDispatchesCirculationData,
  PostDispatchesData,
} from '../../../../../service/dispatch/Dispatch'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../recoil/Atom'
import {isEmptyArray, isNil} from '../../../../../util/ValidationUtil'
import {alertError, throwError} from '../../../../../util/ErrorUtil'
import {Optional} from '../../../../../type/Common'
import {CCourse} from '../../../../../model/Course'
import {DispatchTypeEnum} from '../../../../../enum/DispatchTypeEnum'
import {
  CDispatchCirculationTime,
  CDispatchWeekdayWithCode,
} from '../../../../../model/Dispatch'
import {StudentLoadTypeEnum} from '../../../../../enum/StudentLoadTypeEnum'

type Props = {
  busBrief: CBusBrief
  dispatchPeriod: CDispatchPeriodInfo
  selectedBusInfo: SelectedBusInfo
  onCancel(): void
  onSubmit(): void
  setToast(tc: string): void
  hide(): void
  handleLoading(isLoading: boolean): void
}

export type CirculationDispatches = {
  scheduleDispatchCode: string
  circulationDispatchCode: string
}

export default function DispatchAdd(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const confirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchAdd.tsx] confirmModalRef',
  )
  const notSufficientModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchAdd.tsx] notSufficientModalRef',
  )
  const cancelModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchAdd.tsx] cancelModalRef',
  )

  const [selectedCourse, setSelectedCourse] = useState<Optional<CCourse>>(null)
  const [circulationTime, setCirculationTime] =
    useState<Optional<CDispatchCirculationTime>>(null)
  const [dispatchSchedules, setDispatchSchedules] = useState<
    DispatchSchedule[]
  >([])
  const [isSufficient, setIsSufficient] = useState<boolean>(false)

  const [circulationDispatches, setCirculationDispatches] = useState<
    CirculationDispatches[]
  >([])

  const showCancelModal = useCallback(() => {
    cancelModalRef.current().show()
  }, [])

  const showConfirmModal = useCallback(() => {
    if (
      isEmptyArray(dispatchSchedules) ||
      dispatchSchedules.some(ds => isNil(ds.managerID))
    ) {
      setIsSufficient(false)
      notSufficientModalRef.current().show()
      return
    }

    setIsSufficient(true)
    confirmModalRef.current().show()
  }, [selectedCourse, dispatchSchedules])

  const onChangeManager = useCallback(
    (dispatchCode: string, managerID: number) => {
      setDispatchSchedules(prev => {
        return prev.map(el => {
          if (el.dispatchCode === dispatchCode) {
            return {
              ...el,
              managerID: managerID,
            }
          }

          return el
        })
      })
    },
    [setDispatchSchedules],
  )

  const onChangeWeekday = useCallback(
    (wdcs: CDispatchWeekdayWithCode[]) => {
      setDispatchSchedules(
        wdcs.map(wdc => {
          return {
            weekday: wdc.weekday,
            dispatchCode: wdc.dispatchCode,
            managerID: null,
          }
        }),
      )
    },
    [setDispatchSchedules],
  )

  const onChangeCirculationTime = useCallback(
    (ct: CDispatchCirculationTime) => {
      setCirculationTime(ct)
    },
    [setCirculationTime],
  )

  const onChangeCourse = useCallback(
    (course: CCourse) => {
      setSelectedCourse(course)
    },
    [setSelectedCourse],
  )

  const toastContent = useMemo(() => {
    if (isSufficient) {
      return '배차 등록이 완료 되었습니다.'
    }

    return '배차 등록이 완료 되었습니다.(노선 시간 확정 및 학생, 매니저 등록 제외)'
  }, [isSufficient])

  const addDispatches = useCallback(() => {
    props.handleLoading(true)
    const data: PostDispatchesData = {
      academyID: academyID,
      busID: props.busBrief.bus.id,
      courseCode: selectedCourse.code,
      dispatchPeriodID: props.dispatchPeriod.id,
      dispatchSchedules: dispatchSchedules,
    }

    postDispatches(data)
      .then(() => {
        props.hide()
        props.setToast(toastContent)
        props.onSubmit()
        props.handleLoading(false)
      })
      .catch(error => {
        props.handleLoading(false)
        alertError(
          error,
          `postDispatches() failed. (data: ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [
    selectedCourse,
    dispatchSchedules,
    toastContent,
    props.busBrief,
    props.dispatchPeriod,
    props.hide,
    props.setToast,
    props.onSubmit,
  ])

  const addDispatchesCirculation = useCallback(async () => {
    props.handleLoading(true)

    try {
      const dispatchPromises = circulationDispatches.map(
        ({circulationDispatchCode, scheduleDispatchCode}) => {
          const matchingSchedule = dispatchSchedules.find(
            schedule => schedule.dispatchCode === scheduleDispatchCode,
          )

          if (!matchingSchedule) {
            throw new Error(
              `No matching schedule found for dispatch code: ${scheduleDispatchCode}`,
            )
          }

          const data: PostDispatchesCirculationData = {
            academyID: academyID,
            busID: props.busBrief.bus.id,
            courseCode: selectedCourse.code,
            circulationDispatchCode: circulationDispatchCode,
            dispatchPeriodID: props.dispatchPeriod.id,
            dispatchSchedules: [matchingSchedule],
          }
          console.log(data)
          return postDispatchesCirculation(data)
        },
      )

      await Promise.all(dispatchPromises)

      props.hide()
      props.setToast(toastContent)
      props.onSubmit()
    } catch (error) {
      alertError(error, `postDispatchesCirculation() failed. (error: ${error})`)
    } finally {
      props.handleLoading(false)
    }
  }, [
    selectedCourse,
    dispatchSchedules,
    circulationDispatches,
    toastContent,
    props.busBrief,
    props.dispatchPeriod,
    props.hide,
    props.setToast,
    props.onSubmit,
  ])

  const onSubmit = useCallback(() => {
    if (isNil(selectedCourse)) {
      alert('노선을 선택해주세요')
      return
    }

    if (selectedCourse.dispatchType === DispatchTypeEnum.CIRCULATION) {
      if (isNil(circulationTime)) {
        alert('순환으로 배정할 수업 시간을 선택해주세요')
        return
      }

      addDispatchesCirculation()
      return
    }

    addDispatches()
  }, [addDispatches, circulationTime, selectedCourse, addDispatchesCirculation])

  const footerSufficiency = useMemo(() => {
    if (isNil(selectedCourse)) {
      return false
    }

    if (selectedCourse.dispatchType === DispatchTypeEnum.CIRCULATION) {
      return !isNil(circulationTime)
    }

    return true
  }, [selectedCourse, circulationTime])

  const deleteRadisSavedTempDispatchDetails = useCallback(
    (data: DeleteDispatchesCoursesWithStudentsData) => {
      deleteDispatchesCoursesWithStudents(data)
        .then(cws => {
          // console.log(cws)
        })
        .catch(error => {
          throwError(
            error,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [],
  )

  const fetchCirculationTimes = useCallback(
    async (
      aid: string,
      dispatchCode: string,
    ): Promise<{
      scheduleDispatchCode: string
      times: CDispatchCirculationTime[]
    }> => {
      try {
        const ct = await getDispatchCirculationTime(aid, dispatchCode)
        return {
          times: ct,
          scheduleDispatchCode: dispatchCode,
        }
      } catch (error) {
        throw new Error(
          `getDispatchCirculationTime() failed. (academyID: ${aid}, dispatchCode: ${dispatchCode}, error: ${error})`,
        )
      }
    },
    [],
  )

  const fetchAllCirculationTimes = async () => {
    if (!dispatchSchedules.length || !circulationTime) return

    try {
      const promises = dispatchSchedules.map(schedule =>
        fetchCirculationTimes(academyID, schedule.dispatchCode),
      )

      const results = await Promise.all(promises)

      const matchingDispatches = results.flatMap(
        ({times, scheduleDispatchCode}) =>
          times
            .filter(time => time.dispatchTime === circulationTime.dispatchTime)
            .map(time => ({
              circulationDispatchCode: time.dispatchCode,
              scheduleDispatchCode: scheduleDispatchCode,
            })),
      )

      setCirculationDispatches(matchingDispatches)
    } catch (error) {
      console.error('Error fetching circulation times:', error)
    }
  }

  useEffect(() => {
    fetchAllCirculationTimes()
  }, [
    selectedCourse,
    circulationTime,
    dispatchSchedules,
    academyID,
    fetchCirculationTimes,
    setCirculationDispatches,
  ])

  useEffect(() => {
    fetchAllCirculationTimes()
  }, [])

  return (
    <Container>
      <ConfirmModal
        ref={confirmModalRef.ref}
        header={'배차 등록'}
        content={'배차를 등록 하시겠습니까?'}
        onSubmit={onSubmit}
      />
      <ConfirmModal
        ref={notSufficientModalRef.ref}
        header={'배차 등록'}
        content={
          '노선 시간 확정 및 학생, 매니저 등록이 완료되지 않았습니다. \n배차 먼저 등록 하시겠습니까?'
        }
        onSubmit={onSubmit}
      />

      <ConfirmModal
        ref={cancelModalRef.ref}
        header={'배차 등록 취소'}
        content={
          '작성중인 내용은 저장되지 않습니다.\n배차 등록을 취소 하시겠습니까?'
        }
        onSubmit={() => {
          if (selectedCourse) {
            dispatchSchedules.forEach(schedule => {
              const courseData: DeleteDispatchesCoursesWithStudentsData = {
                dispatchCode: schedule.dispatchCode,
                academyID: academyID,
                courseCode: selectedCourse.code,
                type: StudentLoadTypeEnum.CREATE,
              }
              console.log('courseData', courseData)
              deleteRadisSavedTempDispatchDetails(courseData)
            })

            if (selectedCourse.dispatchType === DispatchTypeEnum.CIRCULATION) {
              console.log(circulationDispatches)
              circulationDispatches.forEach(circulationDispatch => {
                const courseDataCirculation: DeleteDispatchesCoursesWithStudentsData =
                  {
                    dispatchCode: circulationDispatch.circulationDispatchCode,
                    academyID: academyID,
                    courseCode: selectedCourse.code,
                    type: StudentLoadTypeEnum.CREATE,
                  }
                console.log('courseDataCirculation', courseDataCirculation)
                deleteRadisSavedTempDispatchDetails(courseDataCirculation)
              })
            }
          }

          props.onCancel()
        }}
      />

      <HeaderContainer>
        <HeaderText>{props.selectedBusInfo.weekday.exposure}요일</HeaderText>
        <EmphasizedHeaderText>
          {props.selectedBusInfo.classTime}(
          {props.selectedBusInfo.isStart ? '등원' : '하원'})
        </EmphasizedHeaderText>
        <HeaderText>{props.busBrief.bus.name} 배차 등록</HeaderText>
      </HeaderContainer>
      <DispatchAddManage
        busBrief={props.busBrief}
        dispatchPeriod={props.dispatchPeriod}
        selectedBusInfo={props.selectedBusInfo}
        selectedCourse={selectedCourse}
        circulationTime={circulationTime}
        onChangeCourse={onChangeCourse}
        onChangeWeekday={onChangeWeekday}
        onChangeManager={onChangeManager}
        onChangeCirculationTime={onChangeCirculationTime}
        circulationDispatches={circulationDispatches}
      />

      <Footer
        submitText={'등록'}
        onCancel={showCancelModal}
        onSubmit={showConfirmModal}
        isSufficient={footerSufficiency}
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 68rem;
  height: 100%;
  -webkit-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  background-color: #f5f5f5;
`

const HeaderContainer = styled.div`
  ${flexRow};
  border-radius: 1.6rem 0 0 0;
  border-bottom: 0.1rem solid #d9d9d9;
  column-gap: 0.4rem;
  align-items: center;
  background: #ffffff;
  height: 5.6rem;
  padding: 1.6rem;
`

const HeaderText = styled.h5`
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.48px;
`

const EmphasizedHeaderText = styled.h5`
  color: #4b84dd;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.48px;
`
