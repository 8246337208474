import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react'
import styled from 'styled-components'
import {Button, color, flexColumn, flexRow} from '../../../style/CommonStyle'
import CloseButton from '../../../asset/image/close_button.svg'
import SVGImage from '../../common/SVGImage'
import {Optional} from '../../../type/Common'
import Picker, {PickerRef, toPickerOptions} from '../../input/Picker'
import {DispatchTypeEnum} from '../../../enum/DispatchTypeEnum'
import ClearInputIcon from '../../../asset/image/close_yellow.svg'
import DeleteIcon from '../../../asset/image/close_bold.svg'

const isValidTimeFormat = (time: string): boolean => {
  if (time.trim() === '') return undefined

  const timeRegex = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/
  return timeRegex.test(time)
}

type ClassTimeEntry = {
  id: string
  dispatchType: Optional<DispatchTypeEnum>
  classTime: string
  isValidTime?: boolean
  hasTime?: boolean
}

type PopUpProps = {
  onClickCancelAddingClass: () => void
  confirmButton?: (classTimeEntries: ClassTimeEntry[]) => void
  error: Optional<string>
  classTimeEntries: ClassTimeEntry[]
  setClassTimeEntries: Dispatch<SetStateAction<ClassTimeEntry[]>>
  handelAddClass: () => void
  existingTimes?: string[]
}

function AddClass(props: PopUpProps) {
  const pickerRefsMap = useRef(
    new Map<string, React.RefObject<PickerRef<DispatchTypeEnum>>>(),
  )

  const getPickerRef = useCallback((id: string) => {
    if (!pickerRefsMap.current.has(id)) {
      pickerRefsMap.current.set(
        id,
        React.createRef<PickerRef<DispatchTypeEnum>>(),
      )
    }
    return pickerRefsMap.current.get(id)
  }, [])

  const [pickerKey, setPickerKey] = useState<number>(0)

  const dispatchTypePickerOptions = useMemo(() => {
    return toPickerOptions(
      [DispatchTypeEnum.INBOUND, DispatchTypeEnum.OUTBOUND],
      v => v.exposure,
      v => v.exposure,
    )
  }, [])

  const checkDuplicateTime = useCallback(
    (time: string, type: DispatchTypeEnum): boolean => {
      if (!props.existingTimes || !time || !type) return false

      const typeText = type === DispatchTypeEnum.INBOUND ? '시작' : '종료'
      const searchEntry = `${time} ${typeText}`

      return props.existingTimes.includes(searchEntry)
    },
    [props.existingTimes],
  )

  const handleChangeClassTime = useCallback(
    (id: string, value: string) => {
      props.setClassTimeEntries(prev =>
        prev.map(entry =>
          entry.id === id
            ? {
                ...entry,
                classTime: value,
                isValidTime: isValidTimeFormat(value),
                hasTime:
                  value.trim() !== '' && entry.dispatchType
                    ? checkDuplicateTime(value, entry.dispatchType)
                    : false,
              }
            : entry,
        ),
      )
    },
    [checkDuplicateTime],
  )

  const handleChangeDispatchType = useCallback(
    (id: string, value: DispatchTypeEnum) => {
      props.setClassTimeEntries(prev =>
        prev.map(entry =>
          entry.id === id
            ? {
                ...entry,
                dispatchType: value,
                hasTime:
                  entry.classTime.trim() !== ''
                    ? checkDuplicateTime(entry.classTime, value)
                    : false,
              }
            : entry,
        ),
      )
    },
    [checkDuplicateTime],
  )

  const handleAddTimeEntry = useCallback(() => {
    props.setClassTimeEntries(prev => [
      ...prev,
      {
        id: Date.now().toString(),
        dispatchType: null,
        classTime: '',
      },
    ])
  }, [])

  const handleRemoveTimeEntry = useCallback((id: string) => {
    props.setClassTimeEntries(prev => prev.filter(entry => entry.id !== id))
  }, [])

  const handleResetTime = useCallback((id: string) => {
    props.setClassTimeEntries(prev =>
      prev.map(entry =>
        entry.id === id
          ? {
              ...entry,
              classTime: '',
              isValidTime: undefined,
              hasTime: false,
            }
          : entry,
      ),
    )
  }, [])

  const isFormValid = useMemo(
    () =>
      props.classTimeEntries.every(
        entry =>
          entry.dispatchType && // 운행 유형 선택 필수
          entry.classTime.trim() !== '' && // 수업 시간 입력 필수
          entry.isValidTime, // 올바른 시간 형식
        // !entry.hasTime,
        // 중복 시간 아님
      ),
    [props.classTimeEntries],
  )

  const handleClose = useCallback(() => {
    setPickerKey(prev => prev + 1)
    props.onClickCancelAddingClass()
  }, [props.onClickCancelAddingClass])

  return (
    <Container>
      <ImgWrapper onClick={handleClose}>
        <CloseImg source={CloseButton} />
      </ImgWrapper>
      <Header>운행 시간 추가</Header>
      <Noti>
        • <OrangeText>*</OrangeText> 는 필수 입력 항목입니다.
      </Noti>
      <ClassWrapper>
        <AddClassHeader>
          <AddClassTitle>
            운행 유형 및 수업 시간 추가 <OrangeText>*</OrangeText>
          </AddClassTitle>
          <AddTimeButton onClick={handleAddTimeEntry}>
            + 시간 추가
          </AddTimeButton>
        </AddClassHeader>
        {props.classTimeEntries.map(entry => {
          const pickerRef = getPickerRef(entry.id)
          return (
            <SelectList key={entry.id}>
              <SelectContainer>
                <Picker
                  key={`picker-${pickerKey}-${entry.id}`}
                  ref={pickerRef}
                  options={dispatchTypePickerOptions}
                  placeholder="운행 유형 선택"
                  minWidth={159}
                  height={34}
                  onChange={(value: DispatchTypeEnum) =>
                    handleChangeDispatchType(entry.id, value)
                  }
                  defaultIdx={null}
                />
              </SelectContainer>
              <InputWrapper>
                <InputContainer>
                  <Input
                    type="text"
                    placeholder="수업 시간 입력"
                    value={entry.classTime}
                    onChange={e =>
                      handleChangeClassTime(entry.id, e.target.value)
                    }
                    isError={entry.isValidTime === false}
                  />
                  {entry.classTime && (
                    <ResetCourseNameButton
                      onClick={() => handleResetTime(entry.id)}>
                      <ClearImg source={ClearInputIcon} />
                    </ResetCourseNameButton>
                  )}
                </InputContainer>
                {entry.isValidTime === false && (
                  <ErrorMessage>HH:MM 형식으로 입력해주세요.</ErrorMessage>
                )}
                {entry.hasTime && (
                  <InfoMessage>비어있는 모든 요일에 추가됩니다.</InfoMessage>
                )}
              </InputWrapper>
              {props.classTimeEntries.length > 1 && (
                <DeleteButton onClick={() => handleRemoveTimeEntry(entry.id)}>
                  <ClearImg source={DeleteIcon} />
                </DeleteButton>
              )}
            </SelectList>
          )
        })}
      </ClassWrapper>
      <ButtonWrapper>
        <CancelButton onClick={handleClose}>취소</CancelButton>
        <RegisterButton disabled={!isFormValid} onClick={props.handelAddClass}>
          등록
        </RegisterButton>
      </ButtonWrapper>
    </Container>
  )
}

export default AddClass

const Container = styled.div`
  ${flexColumn};
  justify-content: center;
  text-align: center;
  border-radius: 1.6rem;
  position: relative;
  background: #ffffff;
`

const ImgWrapper = styled.div`
  position: absolute;
  top: 1.9rem;
  right: 2rem;
  cursor: pointer;
`

const CloseImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1.6rem 0;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2.4rem;
`

const Noti = styled.div`
  height: 3rem;
  background: #ebebeb;
  padding: 0.8rem 1.6rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
  text-align: left;
  color: #999;
`

const OrangeText = styled.span`
  color: #ed7662;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
`

const ClassWrapper = styled.div`
  ${flexColumn}
  background: #f5f5f5;
  padding: 2rem;
`

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  background: #f0f0f0;
  cursor: pointer;
  padding: 0;
  margin-left: 8px;
  font-size: 12px;
  color: #666;

  &:hover {
    background: #e0e0e0;
  }
`

const AddTimeButton = styled.button`
  padding: 4px 8px;
  border-radius: 16px;
  background: #332a00;
  color: #ffd100;
  border: none;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background: #4a3c00;
  }
`

const ButtonWrapper = styled.div`
  ${flexRow}
  column-gap: 0.6rem;
  justify-content: center;
  padding: 1.2rem;
`

const CancelButton = styled(Button)`
  background: #ffffff;
  color: #000000;
  border: 1px solid #d9d9d9;
`

type ButtonProps = {
  disabled: boolean
}

const RegisterButton = styled(Button)<ButtonProps>`
  background: ${props => (props.disabled ? '#FFF1B3' : '#ffcd00')};
  color: ${props => (props.disabled ? '#cccccc' : '#000')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

const SelectContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
  div {
    font-size: 13px !important;
  }
`

const AddClassHeader = styled.div`
  ${flexRow};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const AddClassTitle = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 700;
  line-height: 19.5px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #997d00;
`

const SelectList = styled.div`
  ${flexRow};
  align-items: center;
  margin-bottom: 17px;

  &:last-child {
    margin-bottom: 0;
  }
`

const InputWrapper = styled.div`
  ${flexColumn};
  max-width: 19.6rem;
  position: relative;
  margin-left: 10px;
`

const ErrorMessage = styled.span`
  position: absolute;
  bottom: -13px;
  left: 5px;
  color: #ed7662;
  font-size: 11px;
  font-weight: 300;
`
const InfoMessage = styled.span`
  position: absolute;
  bottom: -13px;
  left: 5px;
  font-size: 11px;
  font-weight: 300;
`

const Input = styled.input<{isError?: boolean}>`
  border: 1px solid ${props => (props.isError ? '#E74D41' : '#ebebeb')};
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  max-width: 19.6rem;
  border-radius: 8px;
  padding: 0 30px 0 6px; // 오른쪽 패딩 증가
  height: 34px;
  color: ${props => (props.isError ? '#E74D41' : 'initial')};
  ::placeholder {
    font-size: 1.3rem;
    color: #ccc;
  }

  :focus {
    background: #fffbe5;
    border-color: ${props => (props.isError ? '#E74D41' : '#fff183')};
    outline: none;
  }
`

const InputContainer = styled.span`
  position: relative;
  width: 100%;
`

const ResetCourseNameButton = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`

const ClearImg = styled(SVGImage)`
  width: 14px;
  height: 14px;
`
