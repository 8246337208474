import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import {
  alignCenter,
  flexColumn,
  flexRow,
} from '../../../../../../style/CommonStyle'
import ArrowDown from '../../../../../../asset/image/arrowDown.svg'
import SVGImage from '../../../../../common/SVGImage'
import {isEmptyArray, isNil} from '../../../../../../util/ValidationUtil'
import LoadIcon from '../../../../../../asset/image/student.svg'
import ArrowRightIcon from '../../../../../../asset/image/right_arrow_black.svg'
import ManagerSelect, {ManagerSelectRef} from './manager/ManagerSelect'
import {CManager} from '../../../../../../model/Manager'
import {Optional} from '../../../../../../type/Common'
import useSecureRef from '../../../../../../hook/useSecureRef'
import Modal, {ModalRef} from '../../../../../common/Modal'
import {CCourse} from '../../../../../../model/Course'
import {CDate} from '../../../../../../model/Date'
import {
  CDispatchCirculationTime,
  CDispatchCourseWithStudent,
  CDispatchWeekdayWithCode,
} from '../../../../../../model/Dispatch'
import {StudentLoadTypeEnum} from '../../../../../../enum/StudentLoadTypeEnum'
import {DispatchTypeEnum} from '../../../../../../enum/DispatchTypeEnum'
import {
  getDispatchesCoursesWithStudent,
  GetDispatchesCoursesWithStudentsData,
} from '../../../../../../service/dispatch/Dispatch'
import {throwError} from '../../../../../../util/ErrorUtil'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../../recoil/Atom'
import {SelectedBusInfo} from '../../table/PeriodTable'
import LoadStudentNormal from '../../common/loadStudent/normal/LoadStudentNormal'
import LoadStudentCirculation from '../../common/loadStudent/circulation/LoadStudentCirculation'

type Props = {
  selectedCourse: CCourse
  index: number
  dispatchCode: string
  circulationTime: Optional<CDispatchCirculationTime>
  startDate: CDate
  endDate: CDate
  weekdayWithCode: CDispatchWeekdayWithCode
  selectedBusInfo: SelectedBusInfo
  onChangeManager(dc: string, managerID: number): void
  loadedCourses: CDispatchCourseWithStudent[]
  loadedCoursesExtra: CDispatchCourseWithStudent[]
  selectedManagerForAll: CManager
  setIsAllManagerSame: (isAllManagerSame: boolean) => void
}

export default function DispatchAddRouteItem(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const loadStudentNormalRef = useSecureRef<ModalRef>(
    '[DispatchAddRouteItem.tsx] loadStudentNormalRef',
  )
  const loadStudentCirculationRef = useSecureRef<ModalRef>(
    '[DispatchAddRouteItem.tsx] loadStudentCirculationRef',
  )

  const managerSelectRef = useSecureRef<ManagerSelectRef>(
    '[DispatchAddRouteItem.tsx] managerSelectRef',
  )
  const [selectedManager, setSelectedManager] =
    useState<Optional<CManager>>(null)
  const [courses, setCourses] = useState<CDispatchCourseWithStudent[]>([])
  const [extraCourses, setExtraCourses] = useState<
    CDispatchCourseWithStudent[]
  >([])

  const onClickSelectManager = useCallback(() => {
    managerSelectRef.current().show()
  }, [])

  const onChangeManager = useCallback(
    (sm: CManager) => {
      setSelectedManager(sm)
      props.onChangeManager(props.weekdayWithCode.dispatchCode, sm.id)
      props.setIsAllManagerSame(false)
    },
    [setSelectedManager, props.onChangeManager, props.weekdayWithCode],
  )

  const ManagerComponent = useMemo((): ReactNode => {
    if (isNil(selectedManager)) {
      return (
        <OperationRouteInputText hasValue={!isNil(selectedManager)}>
          매니저 선택
        </OperationRouteInputText>
      )
    }

    const managerText = `${selectedManager.name}(${selectedManager.phone.slice(
      9,
    )})`

    return (
      <OperationRouteInputTextContainer>
        <OperationRouteInputText hasValue={!isNil(selectedManager)}>
          {managerText.length > 9
            ? `${managerText.slice(0, 9)}...`
            : managerText}
        </OperationRouteInputText>
      </OperationRouteInputTextContainer>
    )
  }, [selectedManager])

  const onClickLoadStudent = useCallback(() => {
    if (isNil(props.selectedCourse)) {
      return
    }

    if (props.selectedCourse.dispatchType === DispatchTypeEnum.CIRCULATION) {
      loadStudentCirculationRef.current().show()
      return
    }

    loadStudentNormalRef.current().show()
  }, [props.selectedCourse])

  const hideStudentLoadModal = useCallback(() => {
    if (isNil(props.selectedCourse)) {
      return
    }

    if (props.selectedCourse.dispatchType === DispatchTypeEnum.CIRCULATION) {
      loadStudentCirculationRef.current().hide()
      return
    }

    loadStudentNormalRef.current().hide()
  }, [props.selectedCourse])

  const fetchCourses = useCallback(
    (data: GetDispatchesCoursesWithStudentsData, isExtra: boolean) => {
      getDispatchesCoursesWithStudent(data)
        .then(cws => {
          if (isExtra) {
            setExtraCourses(cws)
            return
          }

          setCourses(cws)
        })
        .catch(error => {
          throwError(
            error,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [setCourses],
  )

  const onSubmitStudentLoad = useCallback(() => {
    if (isNil(props.selectedCourse)) {
      return
    }

    const data: GetDispatchesCoursesWithStudentsData = {
      dispatchCode: props.dispatchCode,
      academyID: academyID,
      courseCode: props.selectedCourse.code,
      type: StudentLoadTypeEnum.CREATE,
    }
    fetchCourses(data, false)

    if (isNil(props.circulationTime)) {
      return
    }

    const extraData: GetDispatchesCoursesWithStudentsData = {
      dispatchCode: props.circulationTime.dispatchCode,
      academyID: academyID,
      courseCode: props.selectedCourse.code,
      type: StudentLoadTypeEnum.CREATE,
    }

    fetchCourses(extraData, true)
  }, [
    props.dispatchCode,
    props.circulationTime,
    props.selectedCourse,
    academyID,
    fetchCourses,
  ])

  const getStudentsCount = useCallback(
    (dt: DispatchTypeEnum, cs: CDispatchCourseWithStudent[]) => {
      const count = cs.reduce((acc, curr, idx) => {
        if (dt === DispatchTypeEnum.INBOUND && idx === cs.length - 1) {
          return acc
        }

        if (dt === DispatchTypeEnum.OUTBOUND && idx === 0) {
          return acc
        }

        acc = curr.students.filter(s => s.check).length + acc

        return acc
      }, 0)

      if (count < 1) {
        return 0
      }

      return count - 1
    },
    [],
  )

  const studentInfo = useMemo(() => {
    const firstHasStudentCourse = courses.find(sdcs =>
      sdcs.students.some(s => s.check),
    )
    const firstHasStudentExtraCourse = extraCourses.find(sdcs =>
      sdcs.students.some(s => s.check),
    )

    if (props.selectedCourse.dispatchType === DispatchTypeEnum.CIRCULATION) {
      let dt1
      let dt2

      if (props.selectedBusInfo.isStart) {
        dt1 = DispatchTypeEnum.OUTBOUND
        dt2 = DispatchTypeEnum.INBOUND
      } else {
        dt1 = DispatchTypeEnum.INBOUND // OUTBOUND로 변경
        dt2 = DispatchTypeEnum.OUTBOUND // OUTBOUND로 변경
      }

      // 이후 로직은 그대로 유지

      if (isNil(firstHasStudentCourse) && isNil(firstHasStudentExtraCourse)) {
        return '0명, 0명'
      }

      if (isNil(firstHasStudentCourse) && !isNil(firstHasStudentExtraCourse)) {
        return `0명, ${
          firstHasStudentExtraCourse.students.filter(s => s.check)[0].name
        }외 ${getStudentsCount(dt1, extraCourses)}명`
      }

      if (!isNil(firstHasStudentCourse) && isNil(firstHasStudentExtraCourse)) {
        return `${
          firstHasStudentCourse.students.filter(s => s.check)[0].name
        }외 ${getStudentsCount(dt2, courses)}명, 0명`
      }

      return `${
        firstHasStudentCourse.students.filter(s => s.check)[0].name
      }외 ${getStudentsCount(dt2, courses)}명, ${
        firstHasStudentExtraCourse.students.filter(s => s.check)[0].name
      }외 ${getStudentsCount(dt1, extraCourses)}명`
    }

    if (isNil(firstHasStudentCourse)) {
      return '0명'
    }

    return `${
      firstHasStudentCourse.students.filter(s => s.check)[0].name
    } 외 ${getStudentsCount(props.selectedCourse.dispatchType, courses)}명`
  }, [
    courses,
    extraCourses,
    getStudentsCount,
    props.selectedCourse,
    props.selectedBusInfo,
  ])

  const title = useMemo(() => {
    if (props.selectedCourse.dispatchType === DispatchTypeEnum.CIRCULATION) {
      return `${props.selectedBusInfo.classTime} | ${props.circulationTime.dispatchTime}`
    }

    return props.selectedBusInfo.classTime
  }, [props.selectedBusInfo, props.circulationTime])

  const LoadStudentComponent = useMemo(() => {
    if (isEmptyArray(courses)) {
      return (
        <LoadButtonContainer>
          <LoadButton onClick={onClickLoadStudent}>
            <LoadButtonImage source={LoadIcon} />
            <LoadButtonText>학생 불러 오기</LoadButtonText>
          </LoadButton>
        </LoadButtonContainer>
      )
    }

    return (
      <DispatchInfoContainer>
        <DispatchTime>{title}</DispatchTime>
        <StudentContainer>
          <StudentText>{studentInfo}</StudentText>
          <ShowStudentButton onClick={onClickLoadStudent}>
            <ArrowRight source={ArrowRightIcon} />
          </ShowStudentButton>
        </StudentContainer>
      </DispatchInfoContainer>
    )
  }, [courses, onClickLoadStudent, studentInfo])

  const mangerSelectStyle = useMemo(() => {
    if (props.index % 3 === 2) {
      return {right: 0}
    }

    return {left: -50}
  }, [props.index])

  useEffect(() => {
    setCourses(props.loadedCourses)
    if (props.selectedCourse.dispatchType.value === 'CIRCULATION') {
      setExtraCourses(props.loadedCoursesExtra)
    }
  }, [props.loadedCourses, props.loadedCoursesExtra])

  useEffect(() => {
    setSelectedManager(props.selectedManagerForAll)
  }, [props.selectedManagerForAll])

  const ManagerInputContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ManagerInputContainerRef.current &&
        !ManagerInputContainerRef.current.contains(event.target as Node)
      ) {
        managerSelectRef.current().hide()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [managerSelectRef])

  return (
    <Container>
      <Modal ref={loadStudentNormalRef.ref}>
        <LoadStudentNormal
          dispatchCode={props.dispatchCode}
          course={props.selectedCourse}
          studentLoadType={StudentLoadTypeEnum.CREATE}
          onCancel={hideStudentLoadModal}
          onSubmit={onSubmitStudentLoad}
          selectedBusInfo={props.selectedBusInfo}
        />
      </Modal>

      <Modal ref={loadStudentCirculationRef.ref}>
        <LoadStudentCirculation
          dispatchCode={props.dispatchCode}
          extraDispatchCode={props.circulationTime?.dispatchCode}
          title={props.selectedBusInfo.classTime}
          extraTitle={props.circulationTime?.dispatchTime}
          course={props.selectedCourse}
          studentLoadType={StudentLoadTypeEnum.CREATE}
          isStart={props.selectedBusInfo.isStart}
          onCancel={hideStudentLoadModal}
          onSubmit={onSubmitStudentLoad}
          circulationTime={props.circulationTime}
        />
      </Modal>

      <HeaderContainer>
        <HeaderText>{props.weekdayWithCode.weekday.exposure}요일</HeaderText>
        <ManagerInputContainer ref={ManagerInputContainerRef}>
          <ManagerSelect
            ref={managerSelectRef.ref}
            selectedManager={selectedManager}
            onChange={onChangeManager}
            style={mangerSelectStyle}
            width={300}
          />
          <ManagerInput
            hasValue={!isNil(selectedManager)}
            onClick={onClickSelectManager}>
            {ManagerComponent}
            <ArrowDownImage source={ArrowDown} />
          </ManagerInput>
        </ManagerInputContainer>
      </HeaderContainer>
      {LoadStudentComponent}
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  width: 20rem;
  border-radius: 0.8rem;
`

const HeaderContainer = styled.div`
  width: 100%;
  height: 4.6rem;
  background: #333333;
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // column-gap: 1.6rem;
  border-radius: 0.8rem 0.8rem 0 0;
`

const HeaderText = styled.h5`
  color: #ffd100;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`

const LoadButtonContainer = styled.div`
  width: 100%;
  height: 4.6rem;
  ${flexColumn};
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 0 0 0.8rem 0.8rem;
`

const LoadButton = styled.div`
  width: 17.2rem;
  height: 3rem;
  background: #ffd100;
  ${flexRow};
  align-items: center;
  justify-content: center;
  border-radius: 3.4rem;
  cursor: pointer;
`

const LoadButtonText = styled.div`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`

const LoadButtonImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const DispatchInfoContainer = styled.div`
  ${flexColumn};
  padding: 0.2rem 1.2rem 0.8rem 1.2rem;
  justify-content: space-between;
  border-radius: 0 0 0.8rem 0.8rem;
  background: #ffffff;
`
const DispatchTime = styled.div`
  color: #665300;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`

const StudentContainer = styled.div`
  ${flexRow};
  justify-content: space-between;
  align-items: center;
`

const StudentText = styled.div`
  color: #665300;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const ShowStudentButton = styled.div`
  ${flexRow};
  justify-content: center;
  align-items: center;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 30rem;
  background: #ffd10099;
`

const ArrowRight = styled(SVGImage)`
  width: 1.1rem;
  height: 1.1rem;
`

export const ManagerInputContainer = styled.div`
  position: relative;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 0.8rem;
  width: 13rem;
  height: 3rem;
`

export const ManagerInput = styled.div<{hasValue: boolean}>`
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
  width: 100%;
  background: ${props => (props.hasValue ? '#FFFBE5' : '#ffffff')};
`

export const OperationRouteInputTextContainer = styled.div`
  ${flexRow};
  ${alignCenter};
`

export const OperationRouteInputText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  line-height: 150%;
  font-weight: ${props => (props.hasValue ? 500 : 300)};
  color: ${props => (props.hasValue ? '#000000' : '#cccccc')};
  margin-right: 0.2rem;
`

export const ArrowDownImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`
