import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import Cancel from '../../../../../../../asset/image/close.svg'
import Search from '../../../../../../../asset/image/search.svg'
import PaginationContainer from '../../../../../../common/PaginationContainer'
import styled from 'styled-components'
import {
  alignCenter,
  flexColumn,
  flexRow,
} from '../../../../../../../style/CommonStyle'
import SVGImage from '../../../../../../common/SVGImage'
import TextInput from '../../../../../../input/TextInput'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../../../recoil/Atom'
import {
  getManagers,
  GetManagersData,
} from '../../../../../../../service/manager/Manager'
import {Optional} from '../../../../../../../type/Common'
import {CManager, CManagerVector} from '../../../../../../../model/Manager'
import {isEmptyArray, isNil} from '../../../../../../../util/ValidationUtil'
import PlusIcon from '../../../../../../../asset/image/plus_icon.svg'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import Modal, {ModalRef} from '../../../../../../common/Modal'
import ManagerAdd from './ManagerAdd'

const LIMIT = 10
const PAGE_EXPOSE_COUNT = 3

type Props = {
  width?: number
  selectedManager: Optional<CManager>
  style?: CSSProperties
  onChange(sm: CManager): void
}
export type ManagerSelectRef = {
  show(): void
  hide(): void
}

function ManagerSelectBase(
  props: Props,
  ref: ForwardedRef<ManagerSelectRef>,
): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const addManagerModalRef = useSecureRef<ModalRef>(
    '[ManagerSelect.tsx] addManagerModalRef',
  )

  const [visible, setVisible] = useState<boolean>(false)
  const [managerVector, setManagerVector] =
    useState<Optional<CManagerVector>>(null)

  const fetchManagers = useCallback(
    (data: GetManagersData) => {
      getManagers(data)
        .then(sv => setManagerVector(sv))
        .catch(error => {
          throw new Error(
            `getManagers() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [setManagerVector],
  )

  const onChangePage = useCallback(
    (page: number) => {
      const data: GetManagersData = {
        academyID: academyID,
        page: page - 1,
        size: LIMIT,
      }

      fetchManagers(data)
    },
    [fetchManagers, academyID],
  )

  const onChangeText = useCallback(
    (t: string) => {
      const data: GetManagersData = {
        academyID: academyID,
        page: 0,
        searchType: 'NAME',
        searchValue: t,
        size: LIMIT,
      }

      fetchManagers(data)
    },
    [academyID, fetchManagers],
  )

  const onClickManager = useCallback(
    (sm: CManager) => {
      props.onChange(sm)
      setVisible(false)
    },
    [props.onChange, setVisible],
  )

  const showModal = useCallback(() => {
    addManagerModalRef.current().show()
  }, [])

  const hideModal = useCallback(() => {
    addManagerModalRef.current().hide()
  }, [])

  const ListComponent = useMemo(() => {
    if (isNil(managerVector)) {
      return (
        <EmptyListContainer>검색 결과가 존재하지 않습니다.</EmptyListContainer>
      )
    }

    if (isEmptyArray(managerVector.managers)) {
      return (
        <EmptyListContainer>검색 결과가 존재하지 않습니다.</EmptyListContainer>
      )
    }

    return (
      <ListContainer>
        {managerVector.managers.map((value, idx) => {
          const mn = `${value.name}(${value.phone.slice(9)})`
          const managerName = mn.length > 11 ? `${mn.slice(0, 11)}...` : mn

          return (
            <ManagerContainer
              key={`${value.name}_${idx}`}
              onClick={() => onClickManager(value)}>
              <ManagerTitleContainer>
                <ManagerText
                  selected={props.selectedManager?.name === value.name}>
                  {managerName}
                </ManagerText>
              </ManagerTitleContainer>
            </ManagerContainer>
          )
        })}
      </ListContainer>
    )
  }, [managerVector, props.selectedManager])

  const refresh = useCallback(() => {
    const data: GetManagersData = {
      academyID: academyID,
      page: 0,
      size: LIMIT,
    }

    if (!visible) {
      return
    }

    fetchManagers(data)
  }, [fetchManagers, academyID, visible])

  useEffect(() => {
    const data: GetManagersData = {
      academyID: academyID,
      page: 0,
      size: LIMIT,
    }

    if (!visible) {
      return
    }

    fetchManagers(data)
  }, [visible, academyID])

  useImperativeHandle(
    ref,
    () => ({
      show(): void {
        setVisible(true)
      },
      hide(): void {
        setVisible(false)
      },
    }),
    [setVisible],
  )

  if (!visible) {
    return null
  }

  if (isNil(managerVector)) {
    return null
  }

  return (
    <Container style={{width: props.width, ...props.style}}>
      <Modal ref={addManagerModalRef.ref}>
        <ManagerAdd fetchManagerList={refresh} hideRegisterModal={hideModal} />
      </Modal>
      <HeaderContainer>
        <Header>매니저 선택</Header>
        <HeaderButtonContainer>
          <RouteButton onClick={showModal}>
            <PlusIconImg source={PlusIcon} />
            신규 매니저 등록
          </RouteButton>
          <CancelButton onClick={() => setVisible(false)}>
            <CancelImage source={Cancel} />
          </CancelButton>
        </HeaderButtonContainer>
      </HeaderContainer>
      <SearchContainer>
        <SearchImage source={Search} />
        <StyledTextInput
          onChange={onChangeText}
          placeholder={'검색어를 입력해주세요.'}
          readOnly={false}
          required={false}
        />
      </SearchContainer>
      {ListComponent}
      <PaginationContainer
        totalElementCount={managerVector.paging.totalElements}
        pageExposeCount={PAGE_EXPOSE_COUNT}
        perPage={LIMIT}
        onChange={onChangePage}
      />
    </Container>
  )
}

const ManagerSelect = forwardRef(ManagerSelectBase)
export default ManagerSelect

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 0.8rem;
  ${flexColumn};
  background: #ffffff;
  flex: 1;
  z-index: 3;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
`

const HeaderContainer = styled.div`
  ${flexRow};
  background: #332a00;
  border-radius: 0.8rem 0.8rem 0 0;
  border-bottom: 0.1rem solid #d9d9d9;
  align-items: center;
  justify-content: space-between;
  height: 3.8rem;
`

const HeaderButtonContainer = styled.div`
  ${flexRow};
  column-gap: 0.2rem;
  align-items: center;
`

const CancelButton = styled.div``

const CancelImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.4rem;
`

const Header = styled.div`
  color: #ffd100;
  height: 3rem;
  padding: 0.6rem 0.8rem;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`
const SearchContainer = styled.div`
  padding: 0.6rem 0.8rem;
  background: #f5f5f5;
  ${flexRow};
  border-bottom: 0.1rem solid #ebebeb;
`

const SearchImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`
const StyledTextInput = styled(TextInput)`
  height: 1.9rem;
  border: 0;
  box-shadow: none;
  background: #f5f5f5;

  &::placeholder {
    color: #cccccc;
    font-size: 1.4rem;
  }
`

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.6rem;
  flex: 1;
  width: 100%;
  padding: 1.2rem;
  background: #ffffff;
`

const EmptyListContainer = styled.div`
  flex: 1;
  width: 100%;
  padding: 1.2rem;
  background: #ffffff;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  border-radius: 0 0 0.8rem 0.8rem;
`

const ManagerContainer = styled.div`
  ${flexColumn};
  width: 100%;
  padding: 0.8rem 0;
  border-bottom: 0.1rem solid #ebebeb;
  cursor: pointer;
`

const ManagerTitleContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  justify-content: space-between;
`

const ManagerText = styled.h1<{selected: boolean}>`
  font-size: 1.3rem;
  font-style: normal;
  font-weight: ${props => (props.selected ? 800 : 500)};
  line-height: 150%;
  color: ${props => (props.selected ? '#665300' : '#332A00')};
`

const RouteButton = styled.button`
  border: none;
  background: #ffd100;
  border-radius: 3.4rem;
  width: 11rem;
  height: 2.6rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  padding: 0.4rem 0.8rem 0.4rem 0.6rem;
  ${flexRow};
  align-items: center;
`

const PlusIconImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.2rem;
`
