import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import SVGImage from '../../common/SVGImage'
import {
  CAddressVector,
  CCourseDetail,
  CDefaultCourse,
  CSearchList,
  CSearchPlace,
  CStation,
  CStationDetail,
  CStationDetailCourse,
  CStationStudent,
} from '../../../model/Station'
import SideBarStationDetail from './SideBarStationDetail'
import {
  getCourses,
  getSearchAddress,
  getSearchPlace,
  getSearchStation,
  getSearchStationDetail,
  getStationInfo,
} from '../../../service/station/Station'
import {useRecoilValue} from 'recoil'
import {Optional} from '../../../type/Common'
import {academyIDState} from '../../../recoil/Atom'
import {isEmptyArray, isEmptyString, isNil} from '../../../util/ValidationUtil'
import {SearchStationEnum} from '../../../enum/SearchStationEnum'
import TriangleIcon from '../../../asset/image/left_triangle.svg'
import SideBarCourseDetail from './SideBarCourseDetail'
import SearchHeader from './SearchHeader'
import SearchList from './SearchList'
import StationSideBarEmpty from './StationSideBarEmpty'
import StationSideBarAddCourse, {
  StationSideBarAddCourseRef,
} from './StationSideBarAddCourse'
import {PaginationContainerRef} from '../../common/PaginationContainer'
import useSecureRef from '../../../hook/useSecureRef'
import {Coordinate} from '../kakaoMap/KakaoMap'
import {StationInfo} from './StationSideBarCourseSetting'
import Pagination from '../../common/Pagination'

const PER_PAGE = 15
const PAGE_EXPOSE_COUNT = 3

type StationSideBarProps = {
  isShowStationDetail: boolean
  setIsShowStationDetail: React.Dispatch<React.SetStateAction<boolean>>
  selectedStation?: CStation
  setSelectedStation: React.Dispatch<React.SetStateAction<Optional<CStation>>>
  showDeleteModal: () => void
  showModifyModal: (type: boolean) => void
  station?: CStation
  setStation?: React.Dispatch<React.SetStateAction<CStation>>
  addCourseStatus(s: boolean): void
  refresh: boolean
  stationDeleteRefresh: boolean
  innerHeight: number
  setFixedCenterLatLng: (center: Coordinate) => void
  fixedMarker: (lat: number, lng: number) => void
  onChangeCoordinate(cs: Coordinate[]): void
  setDeleteStatus?: React.Dispatch<React.SetStateAction<boolean>>
  setModifyCourseStatus?: React.Dispatch<React.SetStateAction<boolean>>
  setCopyCourseStatus?: React.Dispatch<React.SetStateAction<boolean>>
  courseDelete: boolean
  setCourseDelete?: React.Dispatch<React.SetStateAction<boolean>>
  setCourseName?: React.Dispatch<React.SetStateAction<Optional<string>>>
  setOperationType?: React.Dispatch<React.SetStateAction<Optional<string>>>
  setCourseDeleteToast?: React.Dispatch<React.SetStateAction<boolean>>
  cancelCloseModifyModal: boolean
  setCancelCloseModifyModal?: React.Dispatch<React.SetStateAction<boolean>>
  setCourseModifyToast?: React.Dispatch<React.SetStateAction<boolean>>
  setCourseAddToast?: React.Dispatch<React.SetStateAction<boolean>>
  setDisableCirculation: React.Dispatch<React.SetStateAction<boolean>>
  mapSelectStation: Optional<CStation>
  addStationStatus: boolean
  setAddStationStatus: React.Dispatch<React.SetStateAction<boolean>>
  setContainStations: React.Dispatch<React.SetStateAction<StationInfo[]>>
  setIsOpenModifyCourse: React.Dispatch<React.SetStateAction<boolean>>
  getCourseRoute: (cs: StationInfo[]) => void
  setCourseRouteData: React.Dispatch<React.SetStateAction<any>>
  setCurrentSelect: React.Dispatch<React.SetStateAction<string>>
  courseDetail: CCourseDetail
  setCourseDetail: React.Dispatch<React.SetStateAction<CCourseDetail>>
  semesterID: Optional<number>
  addNewStation: boolean
  setAddNewStation: React.Dispatch<React.SetStateAction<boolean>>
  centerStation: CStation[]
  setConfirmModifyCourseModal: React.Dispatch<React.SetStateAction<boolean>>
  setConfirmAddCourseModal: React.Dispatch<React.SetStateAction<boolean>>
  addCourseRef?: React.Ref<StationSideBarAddCourseRef>
  dispatchPeriodId: number
  selectedYear: number
  setResetCourseRouteData: React.Dispatch<React.SetStateAction<boolean>>
  reloadOriginalCourse: boolean
  setReloadOriginalCourse: React.Dispatch<React.SetStateAction<boolean>>
}

type CourseProps = {
  id: number
  name: string
  dispatchType: string
}

const StationSideBar: React.FC<StationSideBarProps> = props => {
  const {addCourseRef} = props
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  // const selectedAcademyID = '001000009999'
  // const selectedAcademyID = '001000001002'
  const pageRef = useSecureRef<PaginationContainerRef>(
    '[StationSideBar.tsx] pageRef',
  )
  const [isShow, setIsShow] = useState<boolean>(true)
  const [select, setSelect] = useState<string>('ROUTE')
  const [keyword, setKeyword] = useState<Optional<string>>(null)
  const [courses, setCourses] = useState<Optional<CStationDetailCourse[]>>(null)
  const [stationList, setStationList] = useState<Optional<CSearchList[]>>(null)
  const [addressList, setAddressList] =
    useState<Optional<CAddressVector[]>>(null)
  const [courseList, setCourseList] = useState<Optional<CourseProps[]>>(null)
  const [defaultCourseList, setDefaultCourseList] =
    useState<Optional<CDefaultCourse[]>>(null)
  const [placeList, setPlaceList] = useState<Optional<CSearchPlace[]>>(null)
  const [students, setStudents] = useState<Optional<CStationStudent[]>>(null)
  const [detailCard, setDetailCard] = useState<boolean>(false)
  const [detailCardName, setDetailCardName] = useState<Optional<string>>(null)
  const [stationDetail, setStationDetail] =
    useState<Optional<CStationDetail>>(null)
  const [addCourse, setAddCourse] = useState<boolean>(false)
  const [totalElements, setTotalElements] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [card, setCard] = useState<Optional<CourseProps | CDefaultCourse>>(null)
  const [selectedCard, setSelectedCard] =
    useState<Optional<CourseProps | CDefaultCourse>>(null)
  const [closeModifyCourseStatus, setCloseModifyCourseStatus] =
    useState<boolean>(false)
  const [addNewCourse, setAddNewCourse] = useState<boolean>(false)

  const handleReset = useCallback(() => {
    setStationList(null)
    setAddressList(null)
    setCourseList(null)
    setPlaceList(null)
  }, [])

  useEffect(() => {
    if (props.isShowStationDetail) {
      setIsShow(true)
    }
  }, [props.isShowStationDetail])

  useEffect(() => {
    if (!isNil(props?.selectedStation)) {
      const data = {
        academyID: selectedAcademyID,
        stationID: props.selectedStation?.id.toString(),
        // semesterId: props.semesterID,
        dispatchPeriodId: props.dispatchPeriodId,
        year: props.selectedYear,
      }
      getStationInfo(data)
        .then(res => {
          setCourses(res.courses)
          setStudents(res.student)
        })
        .catch(error => {
          throw new Error(
            `getStationInfo() failed. (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
    }
  }, [
    props?.selectedStation,
    props.semesterID,
    props.selectedYear,
    selectedAcademyID,
  ])

  const handleSearchButton = () => {
    const data = {
      academyID: selectedAcademyID,
      keyword,
      size: PER_PAGE,
      page: currentPage,
      dispatchPeriodId: props.dispatchPeriodId,
    }

    const courseData = {
      academyID: selectedAcademyID,
      keyword,
      size: PER_PAGE,
      page: currentPage,
      dispatchPeriodId: props.dispatchPeriodId,
      year: props.selectedYear,
    }

    if (select === SearchStationEnum.NAME.value && keyword !== '') {
      getSearchStation(data)
        .then(res => {
          setStationList(res.stations)
          setTotalElements(res.paging.totalElements)
        })
        .catch(error => {
          throw new Error(
            `failed to get Search Station List. (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
    }

    if (select === SearchStationEnum.ADDRESS.value && keyword !== '') {
      getSearchAddress(data)
        .then(res => {
          setAddressList(res.address)
          setTotalElements(res.paging.totalElements)
        })
        .catch(error => {
          throw new Error(
            `failed to get Search Address List. (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
    }

    if (
      select === SearchStationEnum.ROUTE.value &&
      keyword !== '' &&
      !isNil(keyword)
    ) {
      getCourses(courseData)
        .then(res => {
          setCourseList(res.courses)
          setTotalElements(res.paging.totalElements)
        })
        .catch(error => {
          throw new Error(
            `failed to get Search courses List. (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
    }

    if (select === SearchStationEnum.PLACE.value && keyword !== '') {
      getSearchPlace(data)
        .then(res => {
          setPlaceList(res.place)
          setTotalElements(res.paging.totalElements)
        })
        .catch(error => {
          throw new Error(
            `failed to get Search place List. (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
    }
  }

  const getDefaultCourseList = () => {
    if (isNil(props.dispatchPeriodId)) {
      return
    }

    const data = {
      academyID: selectedAcademyID,
      keyword: keyword,
      size: PER_PAGE,
      page: currentPage,
      year: props.selectedYear,
      dispatchPeriodId:
        props.dispatchPeriodId === -1 ? null : props.dispatchPeriodId,
    }

    getCourses(data)
      .then(res => {
        setDefaultCourseList(res.courses)
        setTotalElements(res.paging.totalElements)
      })
      .catch(error => {
        throw new Error(
          `failed to get courses List. (data: ${JSON.stringify(
            selectedAcademyID,
          )}, error: ${error})`,
        )
      })
  }

  const handleCloseDetailCard = useCallback(() => {
    setDetailCard(false)
    setDetailCardName(null)
    props.setCourseDetail(null)
    props.setCourseRouteData(null)
  }, [
    props.setCourseRouteData,
    props.setCourseDetail,
    setDetailCard,
    setDetailCardName,
    props.dispatchPeriodId,
    selectedAcademyID,
  ])

  useEffect(() => {
    if (
      (props.isShowStationDetail && props.selectedStation) ||
      (props.isShowStationDetail && props.selectedStation && props.refresh)
    ) {
      getSearchStationDetail(
        selectedAcademyID,
        String(props.selectedStation.id),
        props.dispatchPeriodId === -1 ? null : props.dispatchPeriodId,
        props.selectedYear,
      )
        .then(res => {
          setStationDetail(res)
        })
        .catch(error => {
          throw new Error(
            `failed to get station Detail . (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
    }
  }, [
    props.selectedStation,
    props.isShowStationDetail,
    props.refresh,
    props.selectedYear,
    selectedAcademyID,
  ])

  useEffect(() => {
    setCurrentPage(0)
    setTotalElements(0)
    props.setCurrentSelect(select)
  }, [select, selectedAcademyID])

  useEffect(() => {
    setCurrentPage(0)
  }, [keyword, props.dispatchPeriodId, selectedAcademyID])

  useEffect(() => {
    handleSearchButton()
    setDetailCard(false)
  }, [props.stationDeleteRefresh, selectedAcademyID])

  useEffect(() => {
    handleSearchButton()
    handleCloseDetailCard()
  }, [props.dispatchPeriodId, props.selectedYear, selectedAcademyID])

  const handleAddCourseButton = useCallback(() => {
    setAddCourse(true)
    setAddNewCourse(true)
    setDetailCard(false)
    props.setIsOpenModifyCourse(false)

    setCard(null)
    props.setCourseDetail(null)
    props.setCourseRouteData(null)
  }, [])

  const handleCloseAddCourseButton = useCallback(() => {
    if (addNewCourse) {
      //신규등록일 때
      setAddCourse(false)
      setAddNewCourse(false)
      props.setConfirmAddCourseModal(false)
      props.setResetCourseRouteData(true)
    }
    if (!addNewCourse && props.courseDetail?.dispatches?.length === 0) {
      //수정일 때
      props.setModifyCourseStatus(true)
      return
    }
    //복제일 때
    if (!addNewCourse && props.courseDetail?.dispatches?.length > 0) {
      props.setCopyCourseStatus(true)
      return
    }
  }, [
    props.setModifyCourseStatus,
    props.setCopyCourseStatus,
    props.courseDetail,
    addNewCourse,
    selectedAcademyID,
  ])

  useEffect(() => {
    if (props.cancelCloseModifyModal) {
      props.setCancelCloseModifyModal(false)
    }
    if (closeModifyCourseStatus) {
      setCloseModifyCourseStatus(false)
    }
  }, [props.cancelCloseModifyModal, closeModifyCourseStatus])

  useEffect(() => {
    if (props.refresh || props.stationDeleteRefresh) {
      handleSearchButton()
    }
  }, [props.refresh])

  useEffect(() => {
    if (
      (isEmptyString(keyword) &&
        select === 'ROUTE' &&
        !isNil(props.dispatchPeriodId)) ||
      (isNil(keyword) && select === 'ROUTE' && !isNil(props.dispatchPeriodId))
    ) {
      getDefaultCourseList()
    } else handleSearchButton()
  }, [
    currentPage,
    selectedAcademyID,
    // keyword,
    props.dispatchPeriodId,
    props.selectedYear,
  ])

  useEffect(() => {
    if (isEmptyString(keyword)) {
      setKeyword(null)
      handleReset()
      setCard(null)
      setDetailCard(null)
      props.setCourseDetail(null)
      props.setCourseRouteData(null)
      props.setResetCourseRouteData(true)
    }
  }, [keyword, selectedAcademyID])

  useEffect(() => {
    if (props.isShowStationDetail) {
      setDetailCard(false)
    }
  }, [props.isShowStationDetail, selectedAcademyID])

  useEffect(() => {
    props.addCourseStatus(addCourse)
  }, [addCourse])

  useEffect(() => {
    props.setIsShowStationDetail(false)
  }, [
    stationList,
    placeList,
    courseList,
    defaultCourseList,
    addressList,
    selectedAcademyID,
  ])

  useEffect(() => {
    if (
      isNil(stationList) ||
      isNil(placeList) ||
      isNil(courseList) ||
      isNil(addressList)
    ) {
      setDetailCard(false)
    }
  }, [stationList, placeList, courseList, addressList, selectedAcademyID])

  useEffect(() => {
    if (props.dispatchPeriodId && !props.courseDetail) {
      setCard(null)
      setDetailCard(false)
    }
  }, [props.dispatchPeriodId, props.courseDetail, selectedAcademyID])

  return (
    <SideBar isShow={isShow} sideDetailStatus={detailCard}>
      <ContainerWrapper sideDetailStatus={detailCard}>
        <Container isShow={isShow} sideDetailStatus={detailCard}>
          {!addCourse ? (
            <div>
              <SearchHeader
                detailCard={detailCard}
                keyword={keyword}
                setKeyword={setKeyword}
                handleSearchButton={handleSearchButton}
                handleReset={handleReset}
                setDetailCard={setDetailCard}
                select={select}
                setSelect={setSelect}
                getDefaultCourseList={getDefaultCourseList}
              />

              <ListContainer style={{height: props.innerHeight - 162}}>
                {props.isShowStationDetail ? (
                  <SideBarStationDetail
                    setIsShowStationDetail={props.setIsShowStationDetail}
                    showDeleteModal={props.showDeleteModal}
                    showModifyModal={props.showModifyModal}
                    stationDetail={stationDetail}
                    innerHeight={props.innerHeight}
                  />
                ) : (select === SearchStationEnum.NAME.value &&
                    isEmptyArray(stationList)) ||
                  (select === SearchStationEnum.PLACE.value &&
                    isEmptyArray(placeList)) ||
                  (select === SearchStationEnum.ROUTE.value &&
                    !isEmptyString(keyword) &&
                    isEmptyArray(courseList)) ||
                  (select === SearchStationEnum.ROUTE.value &&
                    !isNil(keyword) &&
                    isEmptyArray(courseList)) ||
                  (select === SearchStationEnum.ROUTE.value &&
                    isEmptyString(keyword) &&
                    isEmptyArray(defaultCourseList)) ||
                  (select === SearchStationEnum.ROUTE.value &&
                    isNil(keyword) &&
                    isEmptyArray(defaultCourseList)) ||
                  (select === SearchStationEnum.ADDRESS.value &&
                    isEmptyArray(addressList)) ? (
                  <StationSideBarEmpty
                    select={select}
                    handleAddCourseButton={handleAddCourseButton}
                  />
                ) : (
                  <SearchList
                    select={select}
                    stationList={stationList}
                    addressList={addressList}
                    courseList={courseList}
                    placeList={placeList}
                    setDetailCard={setDetailCard}
                    setDetailCardName={setDetailCardName}
                    setStation={props.setStation}
                    setStationDetail={setStationDetail}
                    isShowStationDetail={props.isShowStationDetail}
                    setCancelCloseModifyModal={props.setCancelCloseModifyModal}
                    courseDetail={props.courseDetail}
                    setCourseDetail={props.setCourseDetail}
                    refresh={props.refresh}
                    selectedStation={props.selectedStation}
                    fixedCenterLatLng={props.setFixedCenterLatLng}
                    fixedMarker={props.fixedMarker}
                    keyword={keyword}
                    defaultCourseList={defaultCourseList}
                    handleAddCourseButton={handleAddCourseButton}
                    totalElementCount={totalElements}
                    card={card}
                    setCard={setCard}
                    detailCard={detailCard}
                    getCourseRoute={props.getCourseRoute}
                    semesterID={props.semesterID}
                    dispatchPeriodId={props.dispatchPeriodId}
                    selectedYear={props.selectedYear}
                    reloadOriginalCourse={props.reloadOriginalCourse}
                    setReloadOriginalCourse={props.setReloadOriginalCourse}
                  />
                )}
              </ListContainer>
              {totalElements !== 0 && (
                // <PaginationContainer
                //   ref={pageRef.ref}
                //   totalElementCount={totalElements}
                //   pageExposeCount={PAGE_EXPOSE_COUNT}
                //   perPage={PER_PAGE}
                //   onChange={onChangePage}
                // />
                <Pagination
                  num={totalElements}
                  perPage={PER_PAGE}
                  currentPage={currentPage}
                  pageExposeCount={PAGE_EXPOSE_COUNT}
                  setCurrentPage={setCurrentPage}
                  columnGap={'15px'}
                />
              )}
            </div>
          ) : (
            <StationSideBarAddCourse
              handleCloseAddCourseButton={handleCloseAddCourseButton}
              fixedCenterLatLng={props.setFixedCenterLatLng}
              onChangeCoordinate={props.onChangeCoordinate}
              getDefaultCourseList={getDefaultCourseList}
              handleSearchButton={handleSearchButton}
              modifyType={props.courseDetail?.dispatches.length}
              dispatchPeriodId={props.dispatchPeriodId} // 배차 기간 ID
              setCourseDetail={props.setCourseDetail} // 배차 상세 정보
              selectedYear={props.selectedYear} // 선택된 년도
              setCourseModifyToast={props.setCourseModifyToast}
              setCourseAddToast={props.setCourseAddToast}
              setCloseModifyCourseStatus={setCloseModifyCourseStatus}
              addNewCourse={addNewCourse}
              setAddNewCourse={setAddNewCourse}
              setDisableCirculation={props.setDisableCirculation}
              setContainStations={props.setContainStations}
              mapSelectStation={props.mapSelectStation}
              addStationStatus={props.addStationStatus}
              setAddStationStatus={props.setAddStationStatus}
              selectedStation={props.selectedStation}
              setSelectedStation={props.setSelectedStation}
              setOperationType={props.setOperationType}
              setIsOpenModifyCourse={props.setIsOpenModifyCourse}
              addNewStation={props.addNewStation}
              setAddNewStation={props.setAddNewStation}
              centerStation={props.centerStation}
              setConfirmModifyCourseModal={props.setConfirmModifyCourseModal}
              setConfirmAddCourseModal={props.setConfirmAddCourseModal}
              ref={addCourseRef}
              setAddCourse={setAddCourse}
              //디테일 카드 =키기
              setDetailCard={setDetailCard}
              setCard={setCard}
            />
          )}
        </Container>

        {select === SearchStationEnum.NAME.value &&
          detailCard &&
          !isNil(detailCardName) && (
            <SideBarDetail isShow={isShow} sideDetailStatus={detailCard}>
              <SideBarStationDetail
                type={'detail'}
                setIsShowStationDetail={props.setIsShowStationDetail}
                showDeleteModal={props.showDeleteModal}
                showModifyModal={props.showModifyModal}
                stationDetail={stationDetail}
                onClose={() => setDetailCard(false)}
              />
            </SideBarDetail>
          )}

        {select === SearchStationEnum.ROUTE.value &&
          detailCard &&
          !isNil(detailCardName) && (
            <SideBarDetail isShow={isShow} sideDetailStatus={detailCard}>
              <SideBarCourseDetail
                courseName={detailCardName}
                setIsShowStationDetail={props.setIsShowStationDetail}
                courseDetail={props.courseDetail}
                onClose={handleCloseDetailCard}
                handleCloseAddCourseButton={handleCloseAddCourseButton}
                setFixedCenterLatLng={props.setFixedCenterLatLng}
                onChangeCoordinate={props.onChangeCoordinate}
                getDefaultCourseList={getDefaultCourseList}
                handleSearchButton={handleSearchButton}
                setCard={setCard}
                setDeleteStatus={props.setDeleteStatus}
                courseDelete={props.courseDelete}
                setCourseDelete={props.setCourseDelete}
                setCourseName={props.setCourseName}
                setCourseDeleteToast={props.setCourseDeleteToast}
                cancelCloseModifyModal={props.cancelCloseModifyModal}
                closeModifyCourseStatus={closeModifyCourseStatus}
                setCloseModifyCourseStatus={setCloseModifyCourseStatus}
                setCourseModifyToast={props.setCourseModifyToast}
                setDisableCirculation={props.setDisableCirculation}
                mapSelectStation={props.mapSelectStation}
                setContainStations={props.setContainStations}
                addStationStatus={props.addStationStatus}
                setAddStationStatus={props.setAddStationStatus}
                setIsOpenModifyCourse={props.setIsOpenModifyCourse}
                selectedStation={props.selectedStation}
                setSelectedStation={props.setSelectedStation}
                addNewStation={props.addNewStation}
                setAddNewStation={props.setAddNewStation}
                centerStation={props.centerStation}
                setConfirmModifyCourseModal={props.setConfirmModifyCourseModal}
                setConfirmAddCourseModal={props.setConfirmAddCourseModal}
                addCourseRef={addCourseRef}
                setAddCourse={setAddCourse}
                setCourseDetail={props.setCourseDetail}
                dispatchPeriodId={props.dispatchPeriodId}
                selectedYear={props.selectedYear}
                setDetailCard={setDetailCard}

              />
            </SideBarDetail>
          )}
      </ContainerWrapper>
      <Button
        onClick={() => setIsShow(!isShow)}
        isShow={isShow}
        sideDetailStatus={detailCard}
        select={select}>
        <ImgWrapper isShow={isShow}>
          <TriangleImg source={TriangleIcon} />
        </ImgWrapper>
      </Button>
    </SideBar>
  )
}
export default StationSideBar

export type ContainerProps = {
  isShow?: boolean
  sideDetailStatus?: boolean
}

const SideBar = styled.div<ContainerProps>`
  transition: left 0.5s ease-in-out;
  transform: ${props => (props.isShow ? 'translateX(0)' : '')};
  position: absolute;
  left: ${props =>
    props.isShow ? '0' : props.sideDetailStatus ? '-52rem' : '-25.8rem'};
  top: 1.1rem;
  z-index: 99999;
  height: calc(100% - 2.2rem);
`

const ContainerWrapper = styled.div<ContainerProps>`
  display: flex;
  box-shadow: ${props =>
    props.isShow && props.sideDetailStatus
      ? '0 0 12px 0 rgba(0, 0, 0, 0.2)'
      : 'none'};
  height: 100%;
  border-radius: 0 1.6rem 1.6rem 0;
  transition: left 0.5s ease-in-out;
  min-width: 26rem;
  max-width: 52rem;
  transform: ${props => (props.isShow ? 'translateX(0)' : '')};
`

const SideBarDetail = styled.div<ContainerProps>`
  visibility: ${props => !props.isShow && 'hidden'};
  opacity: ${props => !props.isShow && 0};
  transition: ${props =>
    !props.isShow && 'visibility 0s 1s, opacity 1s linear'};
  border-radius: 0 1.6rem 1.6rem 0;
  min-width: 26rem;
  max-width: 26rem;
  background: #fff;
  border-left: 0.1rem solid #ebebeb;
`

const Container = styled.div<ContainerProps>`
  visibility: ${props => !props.isShow && 'hidden'};
  opacity: ${props => !props.isShow && 0};
  transition: ${props =>
    !props.isShow && 'visibility 0s 1s, opacity 1s linear'};
  border-radius: ${props => (props.sideDetailStatus ? 0 : '0 1.6rem 1.6rem 0')};
  background: #fff;
  box-shadow: ${props =>
    props.sideDetailStatus ? 'none' : '0px 0px 12px 0px rgba(0, 0, 0, 0.2)'};
  cursor: default;
  max-width: 26rem;
`

const ListContainer = styled.div`
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`

type ButtonProps = {
  isShow: boolean
  sideDetailStatus: boolean
  select: string
}

const Button = styled.button<ButtonProps>`
  border-radius: 0 0.4rem 0.4rem 0;
  background: #fff;
  position: absolute;
  left: ${props =>
    (props.sideDetailStatus && props.select === 'ROUTE') ||
    (props.sideDetailStatus && props.select === 'NAME')
      ? '52rem'
      : '25.8rem'};
  top: 45%;
  z-index: 99999;
  width: 2rem;
  height: 4rem;
  border: none;
  display: flex;
  justify-content: center;
  padding: 1.4rem 0.4rem;
  transform: ${props => (props.isShow ? 'translateX(0)' : '')};
  transition: transform 0.5s ease-in-out;
`

type RotateProps = {
  isShow: boolean
}

const ImgWrapper = styled.div<RotateProps>`
  transform: ${props => (props.isShow ? '' : 'rotate(180deg)')};
  transition: transform 0.5s ease-in-out;
`

const TriangleImg = styled(SVGImage)`
  width: 1.2rem;
  height: 1.2rem;
`
