import React, {useEffect} from 'react'
import {isNil, isUndefined} from '../../../../../util/ValidationUtil'
import PeriodTableItem from './PeriodTableItem'
import styled from 'styled-components'
import {color, flexColumn, flexRow} from '../../../../../style/CommonStyle'
import {CBusDispatchTable, CBusPeriod} from '../../../../../model/Bus'
import {WeekdayEnum} from '../../../../../enum/WeekdayEnum'

type Props = {
  busPeriod: CBusPeriod
  selectedDispatchTables: CBusDispatchTable[]
  isDeleting: boolean
  isUpdatingStudents: boolean
  onClickAdd(
    ct: string,
    wd: WeekdayEnum,
    isStart: boolean,
    dt: CBusDispatchTable,
  ): void
  onClickInfo(
    ct: string,
    wd: WeekdayEnum,
    isStart: boolean,
    dt: CBusDispatchTable,
  ): void
  onClickCell(dt: CBusDispatchTable): void
}

export default function PeriodTableBody(props: Props) {
  console.log(props)
  if (isUndefined(props.busPeriod)) {
    return null
  }

  if (isNil(props.busPeriod)) {
    return <EmptyContainer>등록된 배차 정보가 없습니다.</EmptyContainer>
  }

  return (
    <TableBox>
      <Thead>
        <ClassTimeHeaderTR>
          <ClassTimeHeaderTH>
            <ClassTimeHeader>수업시간/요일</ClassTimeHeader>
          </ClassTimeHeaderTH>
          {props.busPeriod.weekdays.map((wd, idx) => {
            return <TH key={idx}>{wd.exposure}</TH>
          })}
        </ClassTimeHeaderTR>
      </Thead>
      <TBody>
        {props.busPeriod.classTimes.map((ct, index) => {
          const isStart = ct.slice(6) === '시작'
          return (
            <tr key={index}>
              <ClassTimeTH isStart={isStart}>
                <ClassTime>{ct}</ClassTime>
              </ClassTimeTH>
              {props.busPeriod.dispatchTables[index]?.map((dts, idx) => {
                return (
                  <ItemTD key={`${idx}_${dts.length}`}>
                    <DispatchInfoContainer>
                      {dts.map(dt => {
                        return (
                          <PeriodTableItem
                            key={`${idx}_${dt.dispatch.dispatchCode}`}
                            dispatchTable={dt}
                            selectedDispatchTables={
                              props.selectedDispatchTables
                            }
                            lengthPerCell={dts.length}
                            weekday={props.busPeriod.weekdays[idx]}
                            isStart={isStart}
                            isDeleting={props.isDeleting}
                            isUpdatingStudents={props.isUpdatingStudents}
                            classTime={ct}
                            onClickAdd={props.onClickAdd}
                            onClickInfo={props.onClickInfo}
                            onClickCell={props.onClickCell}
                          />
                        )
                      })}
                    </DispatchInfoContainer>
                  </ItemTD>
                )
              })}
            </tr>
          )
        })}
      </TBody>
    </TableBox>
  )
}

const TableBox = styled.table`
  width: 100%;
  border-spacing: 0.4rem;
  margin-top: -4px;
`
const Thead = styled.thead`
  cursor: default;
  position: sticky;
  top: 52px;
  background: #fff;

  tr {
    padding: 1.2rem;
  }
`

const TH = styled.th`
  background: #000000;
  color: #ffffff;
  border-radius: 0.4rem;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  width: 20rem;
`

const TBody = styled.tbody`
  tr {
    cursor: default;
    border: none;
  }
`

const ClassTimeHeaderTR = styled.tr`
  height: 4rem;
  padding: 0 1.2rem;
  cursor: default;
`

const ClassTimeHeaderTH = styled.th`
  border-radius: 0.4rem;
  background: #332a00;
  width: 9.6rem;
  height: 4rem;
  padding: 0 1.2rem;
  cursor: default;
`

const ClassTimeHeader = styled.pre`
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: #ffffff;
  text-align: center;
`

const ClassTimeTH = styled.th<{isStart: boolean}>`
  border-radius: 0.4rem;
  background: ${props => (props.isStart ? '#009A17' : '#4b84dd')};
  text-align: center;
  padding: 0 1.2rem;
  cursor: default;
`

const ClassTime = styled.div`
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: #ffffff;
  ${flexColumn};
  justify-content: center;
`
const ItemTD = styled.td`
  height: 0;
`

const DispatchInfoContainer = styled.div`
  flex: 1;
  ${flexColumn};
  row-gap: 0.4rem;
  height: 100%;
`

const EmptyContainer = styled.div`
  width: 100%;
  height: 10rem;
  ${flexRow};
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: ${color.grey3};
  line-height: 2rem;
`
